import WebpackerReact from 'webpacker-react';
import Index from '../components/Beetree/pages/Index';
import React, { useState, useEffect } from 'react';
import Preloader from '../GeneralComponents/LoadingComponen';

const PageIndex = (props) => {
    const [data, setData] = useState([]);
    const [data_private_pages, setDataPrivatePage] = useState([]);
    const [isLoaded, setIsLoaded] = useState(true);
    const [page_id, setPageId] = useState("");
    const token = document.querySelector("[name='csrf-token']").content;
  
    useEffect(() => {
        loadData();
    }, []);

    const updateItem = (page) => {
        setData(data.map(item => {
          if (item.id === page.id) {
            return { ...item, 
              active: page.active,
              background_color: page.background_color,
              background_complement_color: page.background_complement_color,
              background_complement_img: page.background_complement_img,
              page_name: page.page_name,
              private: page.private,
              url: page.url,
            }
          }
          return item;
        }));
    }

    const updateData = (new_item) => {
        setData([...data, new_item])
    }

    const handleClickShowView = (e, page) => {
        e.stopPropagation();
        setPageId(page.id);
    }

    const setPageIdFunction = (page_id) => {
        setPageId(page_id);
    }

    const loadData = () => {
        fetch(`/beetree/get_pages`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setIsLoaded(false);
            setData(data.data);
            setPageId(data.page_id);
            setDataPrivatePage(data.private_pages);
        });
    }

    if (isLoaded) {
        return (
            <div className="card">
                <div className="card-body">
                    <Preloader />
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            <Index                
                data={data}
                updateItem={updateItem}
                loadData={loadData}
                updateData={updateData}
                current_url={props.current_url}        
                estados={props.estados}        
                current_user={props.current_user}
                data_private_pages={data_private_pages}
                handleClickShowView={handleClickShowView}
                page_id={page_id}
                setPageIdFunction={setPageIdFunction}
            />
        </React.Fragment>
    );
}

export default PageIndex;
WebpackerReact.setup({ PageIndex });
