import React, { useState, useEffect } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

const Item = (props) => {
    return (
        <div className="">
            <div className="">
                <div className="ui-btree-page-itemContainer">
                    {true && (
                        <div className={`ui-btree-pageCard ${props.index > 0 ? "mt-3" : ""}`} onClick={(e) => props.handleClickShowView(e, props.page)} key={props.page.id}>
                            <div className="ui-bree-cardcontainer">
                                <div className="ui-btree-card-icon-page">
                                    <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/11617/Icon_pages_beetree.png" alt="Icono Páginas beetree" />
                                </div>
                                <div className="ui-btree-card-title_url">
                                    <div className='ui-btree-card-title'>
                                        <p className='ui-btree-title-cardPage'>{props.page.page_name} {props.page.user_id == props.current_user.id ? <React.Fragment><UncontrolledTooltip target='bw-btree-edit-icon'>{'Editar'}</UncontrolledTooltip> <i className="fas fa-pen" onClick={(e) => props.edit(e, props.page)} id='bw-btree-edit-icon'></i></React.Fragment> : null} </p>                                  
                                        <div className="ui-betree-page-active-icons">
                                            {props.page.user_id == props.current_user.id && (
                                                <React.Fragment>
                                                    <UncontrolledTooltip className='btree-uncontrolled-tooltip' target='bw-btree-switch-active-inactive'>{'ACTIVAR/ DESACTIVAR'}</UncontrolledTooltip>
                                                    <div className="custom-control custom-switch" id='bw-btree-switch-active-inactive' onClick={(e) => e.stopPropagation()}>
                                                        <input type="checkbox" className="custom-control-input" id={`customHeader${props.page.id}`} onChange={(e) => props.updateState(e, props.page.id)} value={!props.page.active} checked={props.page.active} />
                                                        <label className="custom-control-label" htmlFor={`customHeader${props.page.id}`}></label>
                                                    </div>

                                                    <UncontrolledTooltip className='btree-uncontrolled-tooltip' target='bw-btree-icon-share_2'>{'COMPARTIR ENLACE'}</UncontrolledTooltip>
                                                    <i className="fas fa-share-alt" onClick={(e) => props.copyLink(e, page)} id='bw-btree-icon-share_2'></i>
                                                </React.Fragment>
                                            )}

                                            {props.page.user_id == props.current_user.id && (
                                                <React.Fragment>                                                                    
                                                    <i className="far fa-eye ui-btree-page-icon-gestion" onClick={(e) => props.handleClickShow(e, props.page)} id='bw-btree-gestion-icon'></i>
                                                    <UncontrolledTooltip target='bw-btree-gestion-icon'>
                                                        {'GESTIONAR PÁGINA'}
                                                    </UncontrolledTooltip> 

                                                    <i className="far fa-trash-alt ui-btree-page-icon-delete" onClick={(e) => props.deletePage(e, props.page.id)} id='bw-btree-delete-icon'></i>
                                                    <UncontrolledTooltip target='bw-btree-delete-icon'>
                                                        {'ELIMINAR PÁGINA'}
                                                    </UncontrolledTooltip> 
                                                </React.Fragment>
                                            )}

                                        </div>
                                    </div>

                                    <div className='ui-btree-card-url'>
                                        <UncontrolledTooltip target='bw-btree-icon-share_1'>{'COMPARTIR ENLACE DE LA PÁGINA'}</UncontrolledTooltip>
                                        <a href={`${props.current_url}${props.page.url}`} target="_blank" onClick={(e) => e.stopPropagation()} id='bw-btree-icon-share_1'>
                                            {props.current_url}{props.page.url} 
                                        </a>                                                        
                                    </div>                                    
                                </div>
                            </div>  

                            <div className="ui-btree-card-name-user-created">
                                <span>Creado por: {props.page.user.name}</span>  

                                {props.page.page_category && (
                                    <React.Fragment>
                                        <div className='float-right'>
                                            Categoria: <span className='badge badge-primary'>{props.page.page_category.name}</span>
                                        </div>  
                                    </React.Fragment>
                                )}

                                {props.page.private && (
                                    <div className="row">
                                        <div className="col-md-6">
                                            <span>Esta pagina esta siendo compartida con los siguientes cargos: </span>  
                                        </div>

                                        <div className="col-md-6">
                                            {page.positions.map((position, index) => (
                                                <span className="badge badge-primary btn-shadow mr-2" key={position.id}>{position.name}</span>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div> 
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}


export default Item;

