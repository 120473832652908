import React, { useState, useEffect } from 'react';
import FormCreate from './FormCreate';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import 'sweetalert2/dist/sweetalert2.min.css'; // Importar los estilos de SweetAlert2
import Pagination from "react-js-pagination";
import Item from '../pages/Item'
import FormCreatePage from '../pages/FormCreate';
import Preloader from '../../../GeneralComponents/LoadingComponen'; 

const Index = (props) => {
    const [data, setData] = useState([]);
    const [dataPages, setDataPages] = useState([]);
    const [isLoaded, setIsLoaded] = useState(true);
    const [isLoadedPages, setIsLoadedPages] = useState(true);
    const [modal, setModal] = useState(false);
    const [modalPage, setModalPage] = useState(false);
    const [modeEdit, setModeEdit] = useState(false);
    const [page_category_id, setPageCategoryId] = useState("");
    const [errorValues, setErrorValues] = useState(true);

    const [id, setId] = useState("");

    const [page_id, setPageId] = useState("");
    const token = document.querySelector("[name='csrf-token']").content;
    const [formPage, setFormPage] = useState({ 
        private: false, 
        page_name: "", 
        url: "", 
        active: true,
        page_category_id: ""
    });
    const [form, setForm] = useState({ 
        name: ""
    });

    useEffect(() => {
        loadData();
    }, []);

    const updateItemPage = (page) => {
        setDataPages(dataPages.map(item => {
            if (item.id === page.id) {
              return { ...item, 
                    active: page.active,
                    background_color: page.background_color,
                    background_complement_color: page.background_complement_color,
                    background_complement_img: page.background_complement_img,
                    page_name: page.page_name,
                    private: page.private,
                    url: page.url,
                }
            }
            return item;
        }));
    }

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const handleChangePage = (e) => {
        setFormPage({ ...formPage, [e.target.name]: e.target.value })
    }

    const clearValuePage = () => {
        setIsLoadedPages(false);
        setFormPage({ private: false, page_name: "", url: "", active: true, page_category_id: "" });
        setPageId("");
        setErrorValues(true);
    }

    const updateDataPage = (new_item) => {
        setDataPages([...dataPages, new_item])
    }

    const updateState = (e, page_id) => {
        const form = {
            id: page_id,
            active: e.target.value,
        }

        fetch(`/beetree/pages/${page_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })

        .then(res => res.json())
        .catch(error => console.error("Error:", error))
        .then(data => {
            if(data.type == "success"){
                setModalPage(false);
                updateItemPage(data.register);
                clearValuePage();
            }else{
                messageSuccess(data);
                clearValuePage();
            }
        });
    }

    const validationForm = () => {
        if (form.page_name != "" &&
            form.url != "" 
        ) {
            setErrorValues(true)
            return true
        }else{
            setErrorValues(false)
            return false
        }
    }

    const handleClickPage = () => {
        if(validationForm()){
            if (page_id == "")
                fetch(`/beetree/pages`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(formPage), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    if(data.type == "success"){
                        setModalPage(false);
                        messageSuccess(data);
                        updateDataPage(data.register);
                        props.setPageIdFunction(data.register.id);
                        clearValuePage();
                    }else{
                        setModalPage(false);
                        messageSuccess(data);
                        clearValuePage();
                    }
                });
            else {
                fetch(`/beetree/pages/${page_id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(formPage), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })

                .then(res => res.json())
                .catch(error => console.error("Error:", error))
                .then(data => {
                    if(data.type == "success"){
                        setModalPage(false);
                        messageSuccess(data);
                        updateItemPage(data.register);
                        clearValuePage();
                    }else{
                        setModalPage(false);
                        messageSuccess(data);
                        clearValuePage();
                    }
                });
            }
        }
    }


    const updateData = (new_item) => {
        setData([...data, new_item])
    }

    const selectPageCategory = (page_category_id) => {
        setIsLoadedPages(true);
        setPageCategoryId(page_category_id);
        fetch(`/beetree/get_page_relation/${page_category_id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(register => {
            setIsLoadedPages(false);
            setDataPages(register.data)
        });
    }

    const updateItem = (category) => {
        setData(data.map(item => {
            if (item.id === category.id) {
                return { ...item, 
                    name: category.name
                }
            }
            return item;
        }));
    }

    const toogle = (from) => {
        if (from == "new") {
            setModal(true);
        } else {
            setModal(false);
            clearValues();
        }
    }

    const clearValues = () => {
        setModeEdit(false);
        setForm({ 
            name: ""
        })
    }

    const loadData = () => {
        fetch(`/beetree/get_page_categories`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setData(data.data);
            setIsLoaded(false);
            if(data.register.data.length >= 1){
                setPageCategoryId(data.register.id);
                setDataPages(data.register.data);
                setIsLoadedPages(false)
            }
        });
    }

    const handleClick = () => {
        if (modeEdit) {
            fetch(`/beetree/page_categories/${id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                messageSuccess(data);
                updateItem(data.register);
                setModal(false);
                clearValues();
            });
        } else {
            fetch(`/beetree/page_categories`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": token,
                    "Content-Type": "application/json"
                }
            })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                messageSuccess(data);
                updateData(data.register);
                setPageCategoryId(data.register.id);
                selectPageCategory(data.register.id);
                setModal(false);
                clearValues();
            });
        }
    }

    const messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };


    const edit = (e, page_category) => {
        e.stopPropagation();
        setModeEdit(true);
        setModal(true);
        setId(page_category.id);
        setForm({ 
            name: page_category.name
        })
    }

    const editPage = (e, page) => {
        e.stopPropagation();
        setFormPage({ private: page.private, page_name: page.page_name, url: page.url, active: page.active, page_category_id: page.page_category_id });
        setPageId(page.id);
        setModalPage(true);
    }

    const deletePageCategory = (e, page_category_id) => {
        e.stopPropagation();
        
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/beetree/page_categories/${page_category_id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    loadData()
                    setPageCategoryId("");
                });
            }
        });
    };

    const deletePage = (e, page_id) => {
        e.stopPropagation();
        
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/beetree/pages/${page_id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    selectPageCategory(page_category_id)
                });
            }
        });
    };

    const onChangeFormUrl = (e) => {
        setFormPage({ ...formPage, [e.target.name]: e.target.value.toLowerCase().replace(" ", "-") })
    }

    const tooglePage = () => [
        setModalPage(true),
        setFormPage({ 
            private: false, 
            page_name: "", 
            url: "", 
            active: true,
            page_category_id: page_category_id
        })
    ]

    const toogleClosePage = () => [
        setModalPage(false),
        setFormPage({ 
            private: false, 
            page_name: "", 
            url: "", 
            active: true,
            page_category_id: ""
        })
    ]

    const styeleIcons = {
        fontSize: "16px",
        color: "#3f69d8",
        cursor: "pointer",
    }

    return (
        <React.Fragment>

            {modal && (
                <FormCreate
                    //modal props
                    backdrop={"static"}
                    modal={modal}
                    toggle={toogle}
                    title={modeEdit ? "Actualizar categoria" : "Añadir categoria"}
                    nameBnt={modeEdit ? "Actualizar" : "Añadir"}

                    //form props
                    formValues={form}
                    submitForm={handleClick}
                    onChangeForm={handleChange}
                    errorValues={true}
                />
            )}


            {modalPage && (
                <FormCreatePage
                    backdrop={"static"}
                    modal={modalPage}
                    toggle={toogleClosePage}
                    title={page_id ? "Actualizar pagina" : "Crear pagina"}
                    nameBnt={page_id ? "Actualizar" : "Crear"}
                    loadSelectData={false}

                    //form props
                    formValues={formPage}
                    onChangeForm={handleChangePage}
                    onChangeFormUrl={onChangeFormUrl}
                    submitForm={handleClickPage}
                    errorValues={errorValues}

                    //url
                    current_url={props.current_url}
                />
            )}

            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <button className='btn-shadow btn btn-info' onClick={() => toogle("new")}>Crear categoria</button>
                        </div>
                        <div className="card-body p-0">
                            {!isLoaded ? (
                                <React.Fragment>
                                    {data.length >= 1 ? (
                                        <React.Fragment>
                                            <ul className="nav nav-tabs mb-0 ui-navTab-Tabs" id="myTab" role="tablist">
                                                {data.map(page_category => (
                                                    <React.Fragment>
                                                        <li className="nav-item" key={page_category.id}>
                                                            <a
                                                                className={`nav-link ${page_category_id == page_category.id ? "active" : ""}`}
                                                                id="home-tab"
                                                                data-toggle="tab"
                                                                onClick={() => selectPageCategory(page_category.id)}
                                                                role="tab"
                                                                aria-disabled={true}
                                                                aria-controls="home"
                                                                aria-selected="true"
                                                            >
                                                                {page_category.name}


                                                                <i className="fas fa-pencil-alt ml-2" style={styeleIcons} onClick={(e) => edit(e, page_category)}></i>
                                                                <i className="fas fa-trash-alt ml-2" style={styeleIcons} onClick={(e) => deletePageCategory(e, page_category.id)}></i>
                                                            </a>
                                                        </li>
                                                    </React.Fragment>
                                                ))}
                                            </ul>
                                        </React.Fragment>
                                    ) : (
                                        <div className="text-center mt-5 mb-5">
                                            <h4>No hay categorias</h4>
                                        </div>
                                    )}
                                </React.Fragment>
                            ) : (
                                <div className="text-center">
                                    <Preloader/>
                                </div>
                            )}
                        </div>
                    </div>

                    {(page_category_id && data.length >= 1) && (
                        <div className="card mt-3">
                            {dataPages.length >= 1 && (
                                <div className="card-header">
                                    <button className='btn-shadow btn btn-info float-right' onClick={() => tooglePage()}>Crear pagina</button>
                                </div>
                            )}
                            <div className="card-body">
                                {!isLoadedPages ? (
                                    <React.Fragment>
                                        {dataPages.length >= 1 ? (
                                            <React.Fragment>
                                                {dataPages.map((page, index) => (
                                                    <Item
                                                        page={page}
                                                        index={index}
                                                        estados={props.estados}
                                                        toogle={toogle}
                                                        current_user={props.current_user}
                                                        copyLink={props.copyLink}
                                                        deletePage={deletePage}
                                                        updateState={updateState}
                                                        handleClickShowView={props.handleClickShowView}
                                                        handleClickShow={props.handleClickShow}
                                                        current_url={props.current_url}
                                                        edit={editPage}
                                                    />
                                                ))}
                                            </React.Fragment>
                                        ) : (
                                            <div className="text-center mt-5 mb-5">
                                                <h4>No hay paginas</h4>
                                                <button className='btn-shadow btn btn-info mt-2' onClick={() => tooglePage()}>Crear pagina</button>
                                            </div>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <div className="text-center">
                                        <Preloader/>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>              
        </React.Fragment>
    );
}


export default Index;
