import React, { useState, useEffect } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import Swal from "sweetalert2/dist/sweetalert2.js";
import FormCreate from './FormCreate';
import MobileView from './MobileView';
import Item from './Item';
import ItemShare from './ItemShare';
import PageCategoryIndex from '../PageCategory/Index';

const Index = (props) => {
    const [form, setForm] = useState({ private: false, page_name: "", url: "", active: true, page_category_id: "" });
    const [showView, setShowview] = useState(false);
    const [id, setId] = useState("");
    const [loadView, setLoadView] = useState(false);
    const [selectedOption, setSelectedOption] = useState({ label: "Categoria", value: "" });
    const [currentTab, setCurrentTab] = useState("categories")

    //modal state
    const [modal, setModal] = useState(false);
    const [modeEdit, setModeEdit] = useState(false);
    const [errorValues, setErrorValues] = useState(true);

    //token
    const token = document.querySelector("[name='csrf-token']").content;

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const onChangeFormUrl = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value.toLowerCase().replace(" ", "-") })
    }

    const validationForm = () => {
        if (form.page_name != "" &&
            form.url != ""
        ) {
            setErrorValues(true)
            return true
        } else {
            setErrorValues(false)
            return false
        }
    }

    const messageSuccess = (response) => {
        Swal.fire({
            position: "center",
            type: `${response.type}`,
            title: `${response.success}`,
            showConfirmButton: false,
            timer: 1500,
        });
    };

    const updateState = (e, page_id) => {
        const form = {
            id: page_id,
            active: e.target.value,
        }

        fetch(`/beetree/pages/${page_id}`, {
            method: 'PATCH', // or 'PUT'
            body: JSON.stringify(form), // data can be `string` or {object}!
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })

            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                if (data.type == "success") {
                    setModal(false);
                    props.updateItem(data.register);
                    clearValues();
                } else {
                    messageSuccess(data);
                    clearValues();
                }
            });
    }

    const handleClick = () => {
        if (validationForm()) {
            if (!modeEdit)
                fetch(`/beetree/pages`, {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        if (data.type == "success") {
                            setModal(false);
                            messageSuccess(data);
                            props.updateData(data.register);
                            clearValues();
                        } else {
                            messageSuccess(data);
                            clearValues();
                        }
                    });
            else {
                setLoadView(false);
                fetch(`/beetree/pages/${id}`, {
                    method: 'PATCH', // or 'PUT'
                    body: JSON.stringify(form), // data can be `string` or {object}!
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })

                    .then(res => res.json())
                    .catch(error => console.error("Error:", error))
                    .then(data => {
                        if (data.type == "success") {
                            setModal(false);
                            messageSuccess(data);
                            setLoadView(true);
                            props.loadData();
                            clearValues();
                        } else {
                            messageSuccess(data);
                            clearValues();
                        }
                    });
            }
        }
    }

    const edit = (e, page) => {
        e.stopPropagation();
        setForm({ private: page.private, page_name: page.page_name, url: page.url, active: page.active, page_category_id: page.page_category_id });
        setId(page.id);
        setModal(true);
        setModeEdit(true);
    }

    const deletePage = (e, page_id) => {
        e.stopPropagation();

        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/beetree/pages/${page_id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        props.loadData()
                    });
            }
        });
    };

    const clearValues = () => {
        setForm({ private: false, page_name: "", url: "", active: true, page_category_id: "" });
        setId("");
        setModeEdit(false);
        setErrorValues(true);
    }

    const copyLink = (e, page) => {
        e.stopPropagation();

        Swal.fire({
            position: "center",
            type: `success`,
            title: `Link copiado`,
            showConfirmButton: false,
            timer: 1500,
        });

        navigator.clipboard.writeText(`${props.current_url}${page.url}`)
    }

    const toogle = (from) => {
        if (from == "new") {
            setModal(true);
        } else {
            setModal(false);
            clearValues();
        }
    }

    const handleClickShow = (e, page) => {
        Turbolinks.visit(`/beetree/page/${page.token}`, "_blank");
    }

    const handleChangeAutocomplete = (selectedOption) => {
        setSelectedOption(selectedOption);
        setForm({ ...form, ["page_category_id"]: selectedOption.value })
    }

    return (
        <React.Fragment>

            {modal && (
                <FormCreate
                    backdrop={"static"}
                    modal={modal}
                    toggle={toogle}
                    title={modeEdit ? "Actualizar pagina" : "Crear pagina"}
                    nameBnt={modeEdit ? "Actualizar" : "Crear"}
                    loadSelectData={true}

                    //form props
                    formValues={form}
                    onChangeForm={handleChange}
                    onChangeFormUrl={onChangeFormUrl}
                    submitForm={handleClick}
                    errorValues={errorValues}

                    //url
                    current_url={props.current_url}
                    handleChangeAutocomplete={handleChangeAutocomplete}
                    selectedOption={selectedOption}
                />
            )}

            {true ? (
                <React.Fragment>
                    <div className="ui-container-betreePage" style={{ display: props.page_id ? "grid" : "initial" }}>
                        <div className="ui-betreePageIndex-cardAll mt-1">

                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" onClick={() => setCurrentTab("categories")}>
                                    <a className={`nav-link ${currentTab == "categories" ? "active" : null}`} id="category-tab" data-toggle="tab" href="#category" role="tab" aria-controls="category" aria-selected="true">Categorias </a>
                                </li>

                                {(props.estados.create && props.data.length >= 1) && (
                                    <li className="nav-item" onClick={() => setCurrentTab("pages")}>
                                        <a className={`nav-link ${currentTab == "pages" ? "active" : null}`} id="home-tab" data-toggle="tab" href="#home-page" role="tab" aria-controls="home-page" aria-selected="true">Paginas <span className="badge badge-light">{props.data.length}</span></a>
                                    </li>
                                )}

                                {props.data_private_pages.length >= 1 && (
                                    <li className="nav-item" onClick={() => setCurrentTab("private_pages")}>
                                        <a className={`nav-link ${currentTab == "private_pages" ? "active" : null}`} id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Paginas compartidas <span className="badge badge-light">{props.data_private_pages.length}</span></a>
                                    </li>
                                )}
                            </ul>

                            <div className="tab-content" id="myTabContent">
                                {currentTab == "categories" && (
                                    <PageCategoryIndex
                                        estados={props.estados}
                                        toogle={toogle}
                                        current_user={props.current_user}
                                        copyLink={copyLink}
                                        deletePage={deletePage}
                                        updateState={updateState}
                                        handleClickShowView={props.handleClickShowView}
                                        setPageIdFunction={props.setPageIdFunction}
                                        handleClickShow={handleClickShow}
                                        current_url={props.current_url}
                                        edit={edit}
                                    />
                                )}

                                {currentTab == "pages" && (
                                    <div className="card">
                                        <div className="card-body ui-btree-page-card_body">
                                            {props.data.length >= 1 ? (
                                                <React.Fragment>
                                                    {props.data.map((page, index) => (
                                                        <Item
                                                            page={page}
                                                            index={index}
                                                            estados={props.estados}
                                                            toogle={toogle}
                                                            current_user={props.current_user}
                                                            copyLink={copyLink}
                                                            deletePage={deletePage}
                                                            updateState={updateState}
                                                            handleClickShowView={props.handleClickShowView}
                                                            handleClickShow={handleClickShow}
                                                            current_url={props.current_url}
                                                            edit={edit}
                                                        />
                                                    ))}
                                                </React.Fragment>
                                            ) : (
                                                <div className="text-center">
                                                    <h4>No hay paginas</h4>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {(props.data_private_pages.length >= 1 && currentTab == "private_pages") && (
                                    <div className={`tab-pane`} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                        <React.Fragment>
                                            {props.data_private_pages.map((page, index) => (
                                                <ItemShare
                                                    page={page}
                                                    index={index}
                                                    estados={props.estados}
                                                    toogle={toogle}
                                                    current_user={props.current_user}
                                                    current_url={props.current_url}
                                                    copyLink={copyLink}
                                                    deletePage={deletePage}
                                                    updateState={updateState}
                                                    handleClickShowView={props.handleClickShowView}
                                                    handleClickShow={handleClickShow}
                                                    edit={edit}
                                                />
                                            ))}
                                        </React.Fragment>
                                    </div>
                                )}
                            </div>
                        </div>

                        {props.page_id && (
                            <div className="ui-complement">

                            </div>
                        )}
                    </div>

                    {props.page_id && (
                        <React.Fragment>
                            <MobileView
                                loadView={loadView}
                                page_id={props.page_id}
                            />
                        </React.Fragment>
                    )}
                </React.Fragment>
            ) : (
                <div className="card">
                    <div className="card-header">
                        <button type="button" onClick={() => toogle("new")} className="btn-shadow btn btn-info ui-btree-page-btnNewPage">
                            Crear Página
                        </button>
                    </div>

                    <div className="card-body" style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/4933/No-hay_paginas_img-f.png" alt="No hay páginas creadas" style={{ width: '25rem' }} />
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}


export default Index;

