import { useState } from 'react';
import React from 'react'
import Dropzone from "react-dropzone";


function evaluarCondicion(condicion, valorColumna, value) {
    switch (condicion) {
        case "=":
            return valorColumna === value;
        case "!=":
            return valorColumna !== value;
        // Agrega más casos para otras condiciones (por ejemplo, "<", ">", "<=", ">=") si es necesario
        default:
            return false; // Condición no válida
    }
}



const Matrix = (props) => {

    console.log(props)
    let valuesArray = [];
    let defaultIsReady = false;
    if (!props.matrix.data_matrix) {
        props.matrix.matrix.map((question_matrix_item, indexFila) => {
            valuesArray.push({ id: question_matrix_item.id, url: "", name: question_matrix_item.name, description: question_matrix_item.description, value: props.matrix.matrix_configuration.default_column, comments: "", dynamic_value: 0, file: "" })
        })


    } else {
        defaultIsReady = true;
        valuesArray = props.matrix.data_matrix
    }

    const [isLoading, setIsLoading] = useState(false);
    const [values, setValues] = useState(valuesArray);
    const [isReady, setIsReady] = useState(defaultIsReady);
    const [saveState, setSaveState] = useState(false);






    const handleChange = (event, rowIndex) => {
        props.setValues("", props.matrix.matrix_configuration.question_id);
        const { name, value } = event.target;
        const updatedValues = [...values]; // Clonar el array de valores



        // Actualizar el valor en la fila correspondiente
        updatedValues[rowIndex][name] = value;

        let resultado_final = checkConditios(rowIndex, value)

        updatedValues[rowIndex].dynamic_value = resultado_final;

        setIsReady(false)
        // Actualizar el estado con los nuevos valores
        setValues(updatedValues);
    };


    ///// CONDICIONES
    const checkConditios = (rowIndex, value) => {
        const conditions = props.matrix.matrix[rowIndex].question_matrix_item_conditions;
        console.log(conditions, "conditions")
        console.log(rowIndex, "rowIndex")
        console.log(value, "value")
        let condicionCumplida = false;
        let resultado_final = 0;
        conditions.forEach((condicion) => {
            if (!condicionCumplida) { // Verifica si aún no se ha cumplido una condición
                const resultado = evaluarCondicion(condicion.condition, condicion.value_column, value);
                if (resultado) {
                    resultado_final = condicion.value
                    condicionCumplida = true; // Marca que una condición se ha cumplido
                }
            }
        });
        console.log(resultado_final, "resultado_final")
        return resultado_final;
    }
    /////

    const handleChangeAndRadio = (event, rowIndex) => {
        props.setValues("", props.matrix.matrix_configuration.question_id);
        const { name, value, type, checked } = event.target;
        const updatedValues = [...values]; // Clonar el array de valores

        // Actualizar el valor en la fila correspondiente
        updatedValues[rowIndex].value = value;
        let resultado_final = checkConditios(rowIndex, value);
        updatedValues[rowIndex].dynamic_value = resultado_final;
        setIsReady(false)
        // Actualizar el estado con los nuevos valores
        setValues(updatedValues);
    };

    const handleChangeCheckbox = (event, rowIndex) => {

        props.setValues("", props.matrix.matrix_configuration.question_id);
        const { value, checked } = event.target;
        const updatedValues = [...values];
        setIsReady(false)
        if (checked) {
            // Agregar la opción seleccionada al array si se marca
            updatedValues[rowIndex].value = ([...updatedValues[rowIndex].value, value]);
            setValues(updatedValues)
        } else {
            // Quitar la opción seleccionada del array si se desmarca
            updatedValues[rowIndex].value = updatedValues[rowIndex].value.filter((option) => option !== value)
            setValues(updatedValues);
        }
    };

    const handleClick = () => {
        // Add validation for mandatory fields
        if (props.matrix.is_mandatory) {
            const hasEmptyValues = values.some(item =>
                !item.value ||
                item.value === "" ||
                (Array.isArray(item.value) && item.value.length === 0)
            );

            if (hasEmptyValues) {
                setSaveState(true);
                return;
            }
        }

        setSaveState(false);
        const updatedValues = [...values]
        updatedValues.map((item, index) => {
            let resultado_final = checkConditios(index, item.value);
            updatedValues[index].dynamic_value = resultado_final;
        })

        setIsLoading(true)
        console.log("setvalues", props.matrix.matrix_configuration.question_id)
        props.setValues("filled", props.matrix.matrix_configuration.question_id); // Llamar a la función desde el padre
        const formData = new FormData();
        values.forEach((objeto, indice) => {

            formData.append(`${objeto.id}`, objeto.file);

        })

        formData.append("data", JSON.stringify(values));



        fetch(`/formatos/update_ansewers_matix/${props.surveyId}/${props.matrix.matrix_configuration.question_id}`, {
            method: 'PATCH', // or 'PUT'
            body: formData, // data can be `string` or {object}!
            headers: {}
        })
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                setIsLoading(false)
                console.log(data.data, "dataa")
                console.log(data.set_value, "set_value")
                setValues(data.data)
                setIsReady(true)
                if (data.set_value) {
                    props.setValues("filled", props.matrix.matrix_configuration.question_id)
                    props.setValues(data.value_to_set.value, data.value_to_set.question)
                }

            });
    };



    const fileQuestion = (rowIndex) => {
        if (values) {
            return (
                <Dropzone
                    onDrop={(files) => HandleChangeFile(files, rowIndex)}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div
                            {...getRootProps({
                                className: 'dropzone-matrix',
                            })}
                        >
                            <input    {...getInputProps()} />
                            <p class="dropzone-matrix-text" style={{ color: values[rowIndex] && values[rowIndex].file ? "#0094ff" : "#a5a5a5" }}>{`${(values[rowIndex] && values[rowIndex].file ? values[rowIndex].file.path : "Arrastre y suelte el archivo aquí, o haga clic para seleccionar el archivo")}`}</p>
                        </div>
                    )
                    }
                </Dropzone >
            )
        }
    }

    const HandleChangeFile = (archivo, rowIndex) => {
        props.setValues("", props.matrix.matrix_configuration.question_id);
        setIsReady(false)
        const updatedValues = [...values];
        console.log(rowIndex)
        console.log(updatedValues)
        console.log(archivo)
        archivo.map(file => (
            updatedValues[rowIndex].file = file
        ));
        setValues(updatedValues)
    }


    const handleChangeComments = (event, rowIndex) => {
        props.setValues("", props.matrix.matrix_configuration.question_id);
        const { name, value, type, checked } = event.target;
        const updatedValues = [...values]; // Clonar el array de valores

        // Actualizar el valor en la fila correspondiente
        updatedValues[rowIndex].comments = value;
        setIsReady(false)
        // Actualizar el estado con los nuevos valores
        setValues(updatedValues);
    }

    const warningMessage = saveState && (
        <div className="alert alert-danger" role="alert">
            Debe completar todos los campos de la matriz
        </div>
    );

    if (props.matrix.matrix_configuration.type_question == "checkbox" || props.matrix.matrix_configuration.type_question == "radio") {
        return (
            <React.Fragment>
                {warningMessage}
                <div style={{ overflowX: 'auto' }}>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                {props.matrix.matrix_configuration.columns.map((columna, index) => (
                                    <React.Fragment>
                                        <th className='text-center' scope="col" key={index}>{columna} </th>
                                    </React.Fragment>
                                ))}
                                {props.matrix.matrix_configuration.has_file && (<th>Evidencia</th>)}
                                {props.matrix.matrix_configuration.has_commets && (<th>Comentarios</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {props.matrix.matrix.map((question_matrix_item, indexFila) => (
                                <tr key={indexFila}>
                                    <td>{question_matrix_item.name}<br /><span style={{ fontSize: "12px", color: "whitesomoke" }}>{question_matrix_item.description}</span></td>
                                    {props.matrix.matrix_configuration.columns.map((columna, index) => (
                                        <td className='text-center' key={index}>
                                            {props.matrix.matrix_configuration.type_question === "checkbox" ? (
                                                <input
                                                    value={columna}
                                                    checked={values[indexFila].value.includes(columna)}
                                                    onChange={(event) => handleChangeCheckbox(event, indexFila)}
                                                    type="checkbox"
                                                />
                                            ) : (
                                                <input
                                                    checked={values[indexFila].value === columna}
                                                    value={columna}
                                                    onChange={(event) => handleChangeAndRadio(event, indexFila)}
                                                    type="radio"
                                                /*   name={`radio_${indexFila}__${question_matrix_item.id}_${columna}`}  */// Nombre único para cada fila
                                                />
                                            )}
                                        </td>
                                    ))}
                                    {props.matrix.matrix_configuration.has_file && (<td className='text-center'>{fileQuestion(indexFila)}
                                        {values[indexFila] && values[indexFila].url &&
                                            <div className="mt-2">   <a className="bw-link-blue" target="_blank" href={`${(values[indexFila] && values[indexFila].url ? values[indexFila] && values[indexFila].url : "")}`}>Ver archivo</a></div>
                                        }</td>)}
                                    <input type="hidden" value={values[indexFila].dynamic_value} />
                                    {props.matrix.matrix_configuration.has_commets && (<td><textarea className='form form-control' type="text" value={values[indexFila].comments} onChange={(event) => handleChangeComments(event, indexFila)} /> </td>)}
                                </tr>
                            ))}
                        </tbody>

                    </table>
                </div>
                <div className='text-right' > <button className={`${!isReady ? "btn btn-success" : "btn btn-light"}`} disabled={isLoading || isReady} onClick={handleClick}>{isLoading ? "Guardando...." : (isReady ? "Guardado" : "Guardar")}</button></div>
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                {warningMessage}
                <table className='table'>
                    <thead>
                        <tr>
                            <th scope="col">Fila</th>
                            <th className='text-center' scope="col">Calificación</th>
                            {props.matrix.matrix_configuration.has_file && (<th>Evidencia</th>)}
                            {props.matrix.matrix_configuration.has_commets && (<th>Comentarios</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {props.matrix.matrix.map((question_matrix_item, index) => (
                            <React.Fragment>
                                <tr>
                                    <td>
                                        {question_matrix_item.name} <br /><span style={{ fontSize: "12px", color: "whitesomoke" }}>{question_matrix_item.description}</span>
                                    </td>
                                    <td>
                                        <select
                                            name="value" // Cambia esto según el nombre de la propiedad en valuesArray
                                            className='form form-control'
                                            value={values[index].value}
                                            onChange={(event) => handleChange(event, index)}
                                        >
                                            <option value="">Seleccione de columna</option>
                                            {props.matrix.matrix_configuration.columns.map((columna, columnIndex) => (
                                                <option value={columna} key={columnIndex}>{columna}</option>
                                            ))}
                                        </select>
                                        {/*  {values[index].dynamic_value} */}
                                    </td>

                                    {props.matrix.matrix_configuration.has_file && (<td>{fileQuestion(index)} {/* <a target="_blank" href={`${(values[index] && values[index].url ? values[index] && values[index].url : "")}`}>Ver archivo</a> */} </td>)}
                                    <input type="hidden" value={values[index].dynamic_value} />
                                    {props.matrix.matrix_configuration.has_commets && (<td><textarea className='form form-control' type="text" value={values[index].comments} onChange={(event) => handleChangeComments(event, index)} /> </td>)}

                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>

                </table>
                <div className='text-right' > <button className={`${!isReady ? "btn btn-success" : "btn btn-light"}`} disabled={isLoading || isReady} onClick={handleClick}>{isLoading ? "Guardando...." : (isReady ? "Guardado" : "Guardar")}</button></div>

            </React.Fragment>
        )
    }
}


export default Matrix;