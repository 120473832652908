import WebpackerReact from 'webpacker-react';
import React, { useState, useEffect } from 'react';
import MobileView from '../components/Beetree/pages/MobileView';
import HeaderMenu from '../components/Beetree/pages/HeaderMenu';
import { UncontrolledTooltip } from 'reactstrap';
import Swal from "sweetalert2/dist/sweetalert2.js";
import Preloader from '../GeneralComponents/LoadingComponen';
// import Carousel from 'carousel-react-rcdev';
import FormCreateMovilTemplate from '../components/Beetree/MovilTemplate/FormCreate'
import HeaderInformation from '../components/Beetree/PageAppearance/HeaderInformation';

// import Swiper core and required modules
import SwiperCore, { Navigation, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Navigation, A11y]);

const PageAppearance = (props) => {
    const [data, setData] = useState([]);
    const [myTemplates, setMyTemplates] = useState([]);
    const [isLoaded, setIsLoaded] = useState(true);
    const [movil_template_id, setMovilTemplateId] = useState("");
    const [currentView, setCurrentView] = useState("templates");
    const [form, setForm] = useState({ 
        page_id: props.page.id,
        is_template: false,
        selected: true,
        background_style: { background: "#f3c153", color: "#ffff", fontFamily: "" },
        button_style: { background: "#ffff", border: "none", color: "#606060", fontFamily: "", boxShadow: "none", borderRadius: "8px" }
    });

    const [loadView, setLoadView] = useState(false);
    const token = document.querySelector("[name='csrf-token']").content;

    // Modal 

    const [modal, setModal] = useState(false);
    const [modeEdit, setModeEdit] = useState(false);

    //Carousel
    const [slidesPerView, setSlidesPerView] = useState(5);
  
    useEffect(() => {
        // Agrega el event listener para detectar cambios de tamaño en la ventana
        window.addEventListener('resize', handleResize);
        handleResize();
        loadData();

        return () => {
            window.removeEventListener('resize', handleResize);
          };
    }, []);

    const handleResize = () => {
        const windowWidth = window.innerWidth;
        if (windowWidth <= 1009) {
          setSlidesPerView(1);
        }  else if (windowWidth >= 1010 && windowWidth <= 1199) {
            setSlidesPerView(2);
        } else if (windowWidth >= 1200 && windowWidth <= 1550) {
            setSlidesPerView(3);
        } else if (windowWidth >= 1551 && windowWidth <= 1750) {
            setSlidesPerView(4);
        } else if (windowWidth >= 1751 && windowWidth <= 2050) {
            setSlidesPerView(5);
        } else if (windowWidth >= 2051 && windowWidth <= 2200) {
            setSlidesPerView(6);
        } else if (windowWidth >= 2201 && windowWidth <= 2500) {
            setSlidesPerView(8);
        } else if (windowWidth >= 2501 && windowWidth <= 2800) {
            setSlidesPerView(10);
        } else if (windowWidth >= 2801 && windowWidth <= 3200) {
            setSlidesPerView(12);
        } else if (windowWidth >= 3201 && windowWidth <= 3600) {
            setSlidesPerView(14);
        } else if (windowWidth >= 3601 && windowWidth <= 4000) {
            setSlidesPerView(16);
        } else {
            setSlidesPerView(17);
        }
    }

    //select template
    const handleChangeTemplate = (field, str) => {
        const new_background_style = {
            ...form.background_style,
            [field]: str, 
        };

        setForm({ 
            ...form, ["background_style"]: new_background_style
        })
    }

    //select background Button  template
    const handleChangeButtonTemplate = (field, str) => {
        const new_button_style = {
            ...form.button_style,
            [field]: str, 
        };

        setForm({ 
            ...form, ["button_style"]: new_button_style
        })
    }



    const updateItem = (movil_template) => {
        setMyTemplates(myTemplates.map(item => {
          if (item.id === movil_template.id) {
            return { ...item, 
                background_style: movil_template.background_style,
                button_style: movil_template.button_style,
            }
          }
          return item;
        }));
    }

    const updateData = (new_item) => {
        setData([...data, new_item])
    }

    const updateMyTemplates = (new_item) => {
        setMyTemplates([...myTemplates, new_item])
    }

    const loadData = () => {
        fetch(`/beetree/get_movil_templates/${props.page.id}`, {
            method: 'GET', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            setData(data.data);
            setMyTemplates(data.my_templates);
            setIsLoaded(false);
        });
    }

    const selectTemplate = (movil_template_id) => {
        setLoadView(false);
        fetch(`/beetree/select_template/${movil_template_id}`, {
            method: 'PATCH', // or 'PUT'
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => {
            updateItem(data.register);
            setLoadView(true);
        });
    }

    const toogle = (from) => {
        if (from == "new") {
            setModal(true);
            setModeEdit(false);
        } else {
            setModal(false);
            setModeEdit(false);
            setForm({ 
                page_id: props.page.id,
                is_template: false,
                selected: true,
                background_style: { background: "#f3c153", color: "#ffff", fontFamily: "" },
                button_style: { background: "#ffff", border: "none", color: "#606060", fontFamily: "", boxShadow: "none", borderRadius: "8px" }
            });
        }
    }

    const handleClick = () => {
        setLoadView(false);
        if(modeEdit){
            fetch(`/beetree/movil_templates/${movil_template_id}`, {
                method: 'PATCH', // or 'PUT'
                body: JSON.stringify(form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": token,
                    "Content-Type": "application/json"
                }
            })
    
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                updateItem(data.register);
                setLoadView(true);
                setModal(false);
                setModeEdit(false);
                setForm({ 
                    page_id: props.page.id,
                    is_template: false,
                    selected: true,
                    background_style: { background: "#f3c153", color: "#ffff", fontFamily: "" },
                    button_style: { background: "#ffff", border: "none", color: "#606060", fontFamily: "", boxShadow: "none", borderRadius: "8px" }
                });
            });
        }else{
            fetch(`/beetree/movil_templates`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(form), // data can be `string` or {object}!
                headers: {
                    "X-CSRF-Token": token,
                    "Content-Type": "application/json"
                }
            })
    
            .then(res => res.json())
            .catch(error => console.error("Error:", error))
            .then(data => {
                updateMyTemplates(data.register);
                setLoadView(true);
                setModal(false);
                setModeEdit(false);
                setForm({ 
                    page_id: props.page.id,
                    is_template: false,
                    selected: true,
                    background_style: { background: "#f3c153", color: "#ffff", fontFamily: "" },
                    button_style: { background: "#ffff", border: "none", color: "#606060", fontFamily: "", boxShadow: "none", borderRadius: "8px" }
                });
            });
        }
    }


    const deleteTemplate = (e, movil_template_id) => {
        e.stopPropagation();
        setLoadView(false);

        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡El registro será eliminado para siempre!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#009688",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                fetch(`/beetree/movil_templates/${movil_template_id}`, {
                    method: "delete",
                    headers: {
                        "X-CSRF-Token": token,
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.json())
                .then(response => {
                    setLoadView(true);
                    setMyTemplates(myTemplates.filter(item => item.id != movil_template_id))
                });
            }
        });
    };

    const editTemplate = (e, template) => {
        e.stopPropagation();

        setForm({
            ...form,
            background_style: template.background_style,
            button_style: template.button_style,
        });

        setMovilTemplateId(template.id);
        setModal(true);
        setModeEdit(true);
    }


    return (
        <React.Fragment>
            <HeaderMenu
                current_page={"appearance"}
                page={props.page}
            />

            {modal && (
                <FormCreateMovilTemplate
                    backdrop={"static"}
                    modal={modal}
                    toggle={toogle}
                    title={modeEdit ? "Actualizar template" : "Crear template"}
                    nameBnt={modeEdit ? "Actualizar" : "Crear"}

                    //form props
                    formValues={form}
                    submitForm={handleClick}
                    page_id={props.page.id}

                    //create template 
                    handleChangeTemplate={handleChangeTemplate}

                    //create button template
                    handleChangeButtonTemplate={handleChangeButtonTemplate}
                />
            )}


            <div className="ui-container-betreePage" style={{ display: "grid"}}>
                <div className="card ui-btree-card-linkContainer pb-0">
                    <div className="card-body p-3">

                        <HeaderInformation
                            enterprise={props.enterprise}    
                        />

                        <div className="ui-btree-appearance-theme">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <a className={`nav-link ${currentView == "templates" ? "active" : ""}`} onClick={() => setCurrentView("templates")}>Temas Preestablecidos</a>
                                </li>
                                
                                <li className="nav-item">
                                    <a className={`nav-link ${currentView == "my_templates" ? "active" : ""}`} onClick={() => setCurrentView("my_templates")}>Tema Personalizados</a>
                                </li>

                                {currentView == "my_templates" && (
                                    <li className="btn btn-outline-info ui-btree-btn-create">
                                        <a onClick={() => toogle("new")}>Creador de Estilos</a>
                                    </li>
                                )}
                            </ul>

                            <div className="tab-content ui-btree-appearance-containerCarousel" id="myTabContent">
                                {currentView == "templates" && (
                                    <Swiper
                                        modules={[Navigation, Scrollbar, A11y]}
                                        spaceBetween={20}
                                        slidesPerView={slidesPerView} 
                                        navigation
                                        onSwiper={(swiper) => console.log(swiper)}
                                        onSlideChange={() => console.log('slide change')}   
                                    >
                                        {!isLoaded ? (                                                    
                                            data.map((movil_template, index) => (                                                
                                                <SwiperSlide className="ui-btree-appearance-preset-item active" onClick={() => selectTemplate(movil_template.id)} key={movil_template.id} style={movil_template.background_style}>
                                                    <div className="ui-btree-appearance-card" style={movil_template.button_style}>Enlace 1</div>
                                                    <div className="ui-btree-appearance-card" style={movil_template.button_style}>Enlace 2</div>
                                                    <div className="ui-btree-appearance-card" style={movil_template.button_style}>Enlace 3</div>
                                                </SwiperSlide> 
                                            ))
                                        ) : (
                                            <Preloader />
                                        )} 
                                    </Swiper>
                                )} 


                                <div className="tab-pane fade show active" id='theme_personalized' role='tabpanel' aria-labelledby="tab-theme_personalized">
                                    {currentView == "my_templates" && (
                                        <React.Fragment>
                                            {myTemplates.length >= 1 ? (
                                                <React.Fragment>
                                                    {myTemplates.length < 5 ? 
                                                        <div className="ui-btree-appearance-present-container">
                                                            <div className="ui-btree-appearance-preset scroll-horizontal-appearance">
                                                                {!isLoaded ? (
                                                                    <React.Fragment>
                                                                        {myTemplates.map((movil_template, index) => (
                                                                            <React.Fragment>
                                                                            <div className="ui-btree-appearance-container-items">
                                                                                <div className="ui-btree-appearance-preset-item" onClick={() => selectTemplate(movil_template.id)} key={movil_template.id} style={movil_template.background_style}>
                                                                                    <div className="ui-btree-appearance-card" style={movil_template.button_style}>Enlace 1</div>
                                                                                    <div className="ui-btree-appearance-card" style={movil_template.button_style}>Enlace 2</div>
                                                                                    <div className="ui-btree-appearance-card" style={movil_template.button_style}>Enlace 3</div>
                                                                                </div> 

                                                                                <i className="fas fa-pencil-alt ui-betree-btn-edit" onClick={(e) => editTemplate(e, movil_template)}></i>
                                                                                <i className="fas fa-trash-alt ui-betree-btn-delete" onClick={(e) => deleteTemplate(e, movil_template.id)}></i>
                                                                            </div>
                                                                            </React.Fragment>  
                                                                        ))}
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <Preloader />
                                                                )}                            
                                                            </div>
                                                        </div>
                                                    :
                                                    <Swiper
                                                        modules={[Navigation, Scrollbar, A11y]}
                                                        spaceBetween={20}
                                                        slidesPerView={slidesPerView} 
                                                        navigation
                                                        onSwiper={(swiper) => console.log(swiper)}
                                                        onSlideChange={() => console.log('slide change')} 
                                                    >
                                                        
                                                        {!isLoaded ? (                                                            
                                                            myTemplates.map((movil_template, index) => (
                                                                <SwiperSlide className="ui-btree-appearance-preset-item" onClick={() => selectTemplate(movil_template.id)} key={movil_template.id} style={movil_template.background_style}>
                                                                        <div className="ui-btree-appearance_icons_container">
                                                                            <i className="fas fa-pencil-alt " onClick={(e) => editTemplate(e, movil_template)}></i>
                                                                            <i className="fas fa-trash-alt " onClick={(e) => deleteTemplate(e, movil_template.id)}></i>
                                                                        </div> 
                                                                        <div className="ui-btree-appearance-card" style={movil_template.button_style}>Enlace 1</div>
                                                                        <div className="ui-btree-appearance-card" style={movil_template.button_style}>Enlace 2</div>
                                                                        <div className="ui-btree-appearance-card" style={movil_template.button_style}>Enlace 3</div>
                                                                    </SwiperSlide> 

                                                                    
                                                                ))                                                            
                                                        ) : (
                                                            <Preloader />
                                                        )}                            
                                                        
                                                    </Swiper>
                                                    }
                                                </React.Fragment>
                                            ) : (
                                                <div className="col-md-12 mt-3">
                                                    <div className="text-center">
                                                        <img src="https://beewo.s3.amazonaws.com/uploads/survey_answer/answer_file/5031/No-hay_plantilla_img-f.png" alt="No hay creado un estilo personalizado" />
                                                    </div>
                                                </div>
                                            )}  
                                        </React.Fragment>
                                    )}
                                </div>                            
                            </div>
                        </div>

                        <div className="ui-btree-appearance-link">
                            <h5></h5>
                        </div>

                        

                    </div>
                </div>
                <div className="ui-complement"></div>
            </div>

            <MobileView 
                page_id={props.page.id}
                loadView={loadView}
            />
        </React.Fragment>
    );
}

export default PageAppearance;
WebpackerReact.setup({ PageAppearance });
